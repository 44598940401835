@import 'react-responsive-carousel/lib/styles/carousel.min.css';

div.carousel-root {
  .carousel {
    position: unset;

    .control-dots {
      width: fit-content;
      bottom: 64px;

      .dot {
        @apply w-1 h-1 rounded-full bg-neutral-darkest #{!important};
        box-shadow: none;
      }

      .selected {
        @apply w-5 #{!important};
      }
    }

    .slide {
      img {
        width: unset;
      }
    }
  }
}
