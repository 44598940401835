.rhap_container {
  @apply text-xs text-neutral-darker shadow-none w-full h-14 bg-neutral-day border border-neutral-light rounded-xl #{!important};

  .rhap_controls-section {
    @apply flex-none;
  }

  .rhap_main-controls-button {
    @apply h-4 w-4;
  }

  .rhap_progress-section * {
    @apply text-xs #{!important};
  }

  .rhap_progress-indicator {
    @apply hidden;
  }

  .rhap_progress-filled {
    @apply bg-neutral-dark #{!important};
  }

  .rhap_current-time {
    @apply order-1 mr-1;
  }

  .rhap_progress-container {
    @apply order-3;
  }

  .rhap_total-time {
    @apply order-1 before:content-['/'] before:mr-1;
  }
}
