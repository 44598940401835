@import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.rdw-editor-wrapper {
  li .public-DraftStyleDefault-block {
    @apply my-1;
  }

  .rdw-editor-toolbar {
    @apply flex flex-row gap-2 px-4 py-2 rounded-t-lg -mb-[51px] bg-neutral-lightest;

    .rdw-option-wrapper {
      @apply p-2 h-auto hover:bg-neutral-dark bg-neutral-lightest rounded border border-neutral-light m-0 h-8 w-8 box-border;

      &:hover,
      &.rdw-option-active {
        @apply shadow-none bg-neutral-dark-0.12;
      }
    }

    .rdw-list-wrapper,
    .rdw-inline-wrapper,
    .rdw-colorpicker-wrapper {
      @apply gap-2 m-0;
    }

    .rdw-colorpicker-wrapper {
      .rdw-colorpicker-option {
        @apply p-0;
        @apply h-6;
        @apply w-6;
      }
    }
  }
}

.rdw-editor-wrapper {
  &.warnings--enabled {
    .rdw-option-wrapper,
    .rdw-editor-toolbar {
      @apply bg-warning-lighter;
    }
  }
}
