.modal__body {
  @apply font-normal text-sm font-inter leading-[155%] tracking-tight text-neutral-darkest;

  h1 {
    @apply text-base mb-4 mt-10 font-medium;
  }

  p {
    &:last-child {
      @apply mb-0;
    }

    @apply mb-4;
  }

  section {
    &:first-child {
      @apply -mt-10;
    }
  }
}
