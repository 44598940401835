button[role='tab'] {
  @apply text-neutral-dark;

  &[data-orientation='vertical'] {
    @apply m-0 pt-4 px-4 bg-neutral-lightest border-l-2 border-y-2;

    &:first-of-type {
      @apply m-0;
    }
  }
}

button[role='tab'][data-state='active'] {
  @apply border-b-neutral-darkest;

  &[data-orientation='vertical'] {
    @apply border-b-neutral-lightest border-t-neutral-lightest border-l-neutral-darkest bg-white;
  }
}

button[role='tab']:hover {
  @apply text-neutral-darkest;

  > span {
    @apply bg-neutral-darkest text-white;
  }
}

div[role='tablist'] {
  &[data-orientation='vertical'] {
    @apply flex-col;
  }
}

div[role='tabpanel'] {
  &[data-orientation='vertical'] {
    @apply flex-grow;
  }
}
