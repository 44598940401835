.custom__radio {
  input[type='radio'] {
    @apply grid place-content-center w-5 h-5 cursor-pointer outline-none;

    @apply appearance-none bg-neutral-day m-0;

    @apply border-solid border-2 border-neutral-light rounded-full;
  }

  input[type='radio']::before {
    content: '';

    @apply w-3 h-3 rounded-full transition-all bg-neutral-darkest scale-0;
  }

  input[type='radio']:checked {
    @apply border-neutral-darkest;
  }

  input[type='radio']:checked:disabled {
    @apply border-neutral-light;
  }

  input[type='radio']:checked::before {
    @apply scale-100;
  }

  [type='radio']:disabled {
    @apply cursor-not-allowed border-neutral-light;
  }

  [type='radio']:disabled::before {
    @apply bg-neutral-light;
  }
}
