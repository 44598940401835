@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

$font-family: Inter, Arial, Helvetica, sans-serif;

* {
  font-family: $font-family;
  @apply text-neutral-darkest;
}

body {
  --toastify-color-light: theme('colors.neutral-darkest');
  --toastify-font-family: $font-family;
  overflow-x: hidden;
}

a {
  @apply text-info-medium;
}

path,
rect {
  vector-effect: non-scaling-stroke;
}

// @todo: remove once we have a proper placeholder
.loader {
  border-top-color: #ff5076;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@layer components {
  .shadow-small {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
  }

  .gradient-orange {
    background: linear-gradient(180deg, #fff5e6 0%, rgba(255, 245, 230, 0) 100%);
  }

  .gradient-green {
    background: linear-gradient(180deg, #e6f9f1 0%, rgba(230, 249, 241, 0) 100%);
  }
}

// toasts

.Toastify {
  .Toastify__toast-container {
    @apply left-1/2 max-w-[95vw] -translate-x-1/2 sm:left-auto sm:translate-x-0;
  }

  .Toastify__toast {
    @apply rounded-lg mb-4;

    * {
      @apply text-white;
    }

    .Toastify__close-button {
      svg {
        @apply stroke-white;
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

// Clerk form (not tagetable via appearance prop)
.cl-formButtonPrimary {
  span[aria-busy='true'][aria-label='Loading'] {
    @apply border-b-white border-l-white;
  }
}
