.custom__checkbox {
  [type='checkbox'] {
    @apply grid place-content-center w-4 h-4 cursor-pointer grow-0 transition-all;

    @apply text-neutral-darkest appearance-none bg-white;

    @apply py-[2px] border-solid border-2 border-neutral-light rounded;

    -webkit-appearance: none;

    &::before {
      content: '';

      @apply text-transparent block w-3 h-3 bg-transparent border-brand-light;
    }
  }

  [type='checkbox']:checked {
    @apply bg-current border-none;

    &::before {
      @apply shadow-none bg-no-repeat bg-checked bg-contain bg-center;
    }
  }

  [type='checkbox']:disabled {
    @apply border-neutral-lighter cursor-not-allowed text-neutral-light;
  }

  [type='checkbox'][data-partial-selected='true'] {
    @apply bg-current border-none;

    &::before {
      content: '-';
      @apply flex items-center justify-center text-center text-white;
    }
  }
}
