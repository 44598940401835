.rsw_2Y,
.rsw_2f,
.rsw_3G,
.rsw_1z,
.rsw_3C,
.rsw_1u {
  @apply w-full h-full flex-grow flex flex-col justify-center items-center;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1.5s;
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 1.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
