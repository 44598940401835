// Google's place autocomplete customizations
.pac-container {
  @apply text-neutral-darkest font-[inherit] shadow border;

  .pac-item {
    @apply cursor-pointer px-4 py-2 text-base;
  }

  .pac-item-query {
    @apply text-base;
  }

  .pac-icon {
    @apply hidden;
  }
}
